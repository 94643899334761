import {Cardstructure, fetching} from "../Generals"
import {Alert, Col, Row} from "react-bootstrap";
import {useEffect,useState} from "react";
import {MedalOutline} from "react-ionicons"

export const CursosCompletados = (props) => {
    let {user,global} = props
    let [cursos,setCursos]=useState(null)
    const loadCompletados = () =>{
        let params = {
            userid:user.user.id_alumno
        }
        let url=global.domain+"api/v1/getCursosCompletados/"+global.apicode+'/'
        fetching(url,params,setCursos,null)
    }

    useEffect(()=>{
        loadCompletados()
    },[])


    return <div className={'text-center py-5'}>
        <h3 className={'mb-4 font-weight-medium'}>Cursos completados</h3>
        {cursos !== null && cursos.length > 0 ?
            <Row className={'justify-content-center'}>
                {cursos.map(item=>{
                    return <Col lg={4} md={3} sm={12}>
                        <Cardstructure img={global.domain+item.cursos_curso.img} title={item.cursos_curso.nombre}>
                            <MedalOutline
                                color={'#00000'}
                                height="30px"
                                width="30px"
                            />
                        </Cardstructure>
                    </Col>})
                }
            </Row>
            :
            <Alert variant={'info'}>No tiene cursos completados</Alert>
        }
    </div>

}
