import {useEffect, useState} from "react"
import { Loader, Moneyformat, fetching} from "../Generals"
import {Row,Col} from "react-bootstrap"

export const Comprar = (props) => {
    let {global,paramstoload} = props
    const [content,setContent]=useState(null)
    const [currency,setCurrency]=useState(null)
    const [total,setTotal]=useState(null)
    const [precio,setPrecio]=useState(null)
    const [qty,setQty]=useState(1)
    const[facturaElectronica,setFacturaElectronica]=useState(false)

    const loadContent = () => {
        let params = {
            itemid:paramstoload.itemid
        }
        let url=global.domain+"api/v1/comprarData/"+global.apicode+'/'
        fetching(url,params,setContent,null)
    }

    useEffect(()=>loadContent(),['content'])
    if(currency === null && content !==null){
        setCurrency(content.currencies[0])
    }

    const changeQty = (val) =>  {
        setQty(val)
        setTotal(val*precio)
    }

    if(currency !== null && precio == null){
        let prec=content.producto.precio
        setPrecio(prec)
        setTotal(prec*qty)
    }

    console.log(facturaElectronica)

    return <>
        {content === null || currency === null ? <Loader/> :
            <Row className={'justify-content-center py-5'}>
                <Col xs={12} sm={10} md={8}>
                    <div className="card rounded-0">
                        <div className="card-header">
                            <h5 className="m-0 font-weight-light">Estas comprando el
                                producto <strong>{content.producto.name}</strong> <span
                                    className="float-right"><Moneyformat value={content.producto.precio} currency={currency} /></span></h5>
                        </div>
                        <div className="card-body">
                                <input type="hidden" name="productid" value="<?=$producto->id?>"/>
                                <input type="hidden" className="preciounitario" name="preciounitario"
                                       value="<?=moneyunformatted($producto->precio,$currency)?>"/>
                                <input type="hidden" className="symbol" name="symbol" value="<?=$currency->simbolo?>"/>
                                <input type="hidden" className="total" name="total"
                                       value="<?=moneyunformatted($producto->precio,$currency)?>"/>
                                <label htmlFor="qty">Cantidad</label>
                                <div className="input-group mb-3  input-group-lg">
                                    <input className={'form-control'} type="number" defaultValue={qty} onChange={(e)=>changeQty(e.target.value)}/>
                                </div>
                                <h4 className="font-weight-light">Total: <strong
                                    className="formatted_total text-success"><Moneyformat value={total} currency={currency} /></strong></h4>

                            <div className="rowv border-top py-4 mt-4">
                                <div className="col">
                                    <label>Formas de pago</label>
                                    <select className="form-control form-control-lg" name="formadepago">
                                        {content.formas_pago.map(item=><option value={item.id}>{item.name}</option>)}
                                    </select>
                                </div>
                                <div className="col">
                                    <label className={' mt-4'}>Requiere factura electrónica</label>
                                    <select onChange={e=>setFacturaElectronica(e.target.value)}
                                            className="form-control form-control-lg facturaelectronica"
                                            name="facturaelectronica">
                                        <option value={false}>No</option>
                                        <option value={true}>Si</option>
                                    </select>
                                </div>
                            </div>
                            {facturaElectronica === 'true' ?
                                <div className="row electricaspacefields">
                                    <div className="col">
                                        <label>RFC <i className="fa fas fav fa-asterisk text-danger"></i></label>
                                        <input type="text" required={true} className="form-control electricalfield" name="rfc"/>
                                    </div>
                                    <div className="col">
                                        <label>Razón Social <i className="fa fas fav fa-asterisk text-danger"></i></label>
                                        <input type="text" required={true} className="form-control electricalfield" name="razon_social"/>
                                    </div>
                                </div>
                            : null}
                            <button className={'btn btn-info'}>Procesar orden</button>
                        </div>
                    </div>
                </Col>
            </Row>
        }
        </>
}

