import {ChevronBackOutline, ChevronForwardOutline, LocateOutline} from 'react-ionicons'
import {Alert, Card, Pagination} from 'react-bootstrap'
import dateformat from "dateformat"

export const Title = props => <h3 className={'mb-3 light'}>{props.value}</h3>

export const fetching = (url,params=null,fn,storage=null) => {
    if(params !==  null){
        params=btoa(JSON.stringify(params))
    }
    let urlpass=url+'/'+params
    fetch(urlpass).then(result=>result.text()).then(json=>{
        json=JSON.parse(json)
        if(storage !== null){
            storeLocal(storage,json.result)
        }
        if(fn !== null && fn !== undefined){
            fn(json.result)
        }
        return
    })
}

export const Numberformat = props => <span>{new Intl.NumberFormat('en-US').format(props.value)}</span>

export const Moneyformat = props => <span {...props}><small>{props.currency.simbolo}</small>$<Numberformat value={props.value*props.currency.exchange}/></span>

export const dateformating = (value) => dateformat(value, 'dd mmm h:MM TT')

export const Cardstructure = (props) => <Card {...props}>
    {props.img !== undefined && props.img !== null ? <div className={'bgimgh'} style={{height:150, backgroundImage:'url('+props.img+')'}}></div> : null}
    <Card.Body>
        {props.title !== undefined && props.title !== null ? <Card.Title>{props.title}</Card.Title>:null}
        {props.text !== undefined && props.text !== null ? <Card.Text>{props.text}</Card.Text>:null}
        {props.children}
    </Card.Body>
</Card>

export const changeView = (viewid,itemid,setParamstoload,paramstoload,setLoading) => {
    setLoading(true)
    let newObject=paramstoload
    newObject['view']=viewid
    newObject['itemid']=itemid
    setParamstoload(newObject)
    setTimeout(()=>setLoading(false),250)
    return
}

export const GlobalIndex = (props) => {
    let {title, data, content, changePage, disabledval, disabledvalnext, page, pages}=props
    return <Cardstructure title={title} className={'card-contact-one'}>
        {data === null ? <Loader/> : <>{data.length > 0 ? <><ul className={'list-group list-group-flush'}>
            {data.map(item=>{
                return content(item)
            })}
        </ul>
            <div className={'d-flex justify-content-between'}>
                <Pagination className={'my-3'}>
                    <Pagination.Item
                        onClick={()=>changePage(page-1)}
                        disabled={disabledval}>
                        <ChevronBackOutline
                            color={"#ccc"}
                            height="20px"
                            width="20px"
                        />
                    </Pagination.Item>

                    <Pagination.Item
                        onClick={()=>changePage(page+1)}
                        disabled={disabledvalnext}>
                        <ChevronForwardOutline
                            height="20px"
                            color={"#ccc"}
                            width="20px"
                        />
                    </Pagination.Item>
                </Pagination>
                <span className={'my-3'}>{page}/{pages}</span>
            </div>
        </> : <Alert>No hay resultados.</Alert>}</> }
    </Cardstructure>
}

export const storeLocal = (key,value) => localStorage.setItem(key,JSON.stringify(value))

export const Loader = () => <div className={'loaderspace'}><LocateOutline rotate height="35px" width="35px" /></div>
