import {Row,Col,Alert} from 'react-bootstrap'
import {Cardstructure, changeView} from "../Generals"

export const ChooseConsulta = (props) => {
    let {global,user,setParamstoload,paramstoload,setLoading} = props

    return <div className={'text-center py-5'}>
        <h3 className={'mb-4 font-weight-medium'}>¿Cuál curso quiere consultar?</h3>
        {user.activeCursos !== undefined && user.activeCursos.length > 0 ?
            <Row>
                {user.activeCursos.map(item=><Col key={'chooeconsl'+item.id}><Cardstructure width={33} img={global.domain+item.cursos_curso.img} title={item.cursos_curso.nombre}>
                    <button
                        onClick={()=>changeView(14,{cursoid:item.cursos_curso.id, itemid:null},setParamstoload,paramstoload,setLoading)}
                        className="mt-3 btn btn-block w-100 btn-success">Consultar aquí</button>
                </Cardstructure></Col>)}
            </Row>
            :
            <Alert variant={'info'}>No tiene cursos activos</Alert>
        }
    </div>
}

