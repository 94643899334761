import {fetching, Loader,changeView,dateformating} from "../Generals"
import {useState, useEffect, Component} from "react"
import noimg from './../no-img.jpg'


const Itemchat = (props) => {
    let content=props.content
    let global=props.global
    let field='nombre'

    if(props.type === 1){
        field='Nombre'
    }

    let img = noimg
    if(content.img !== null ){
        img=global.domain+content.img
    }
    return <div key={'contentitem'+props.type+content.id} className={'chat-item'}>
        <div className="avatar avatar-online "><span className="avatar-initial bgimgh rounded-circle" style={{backgroundImage:'url('+img+')'}}></span></div>
        <div className="chat-item-body"><div><span>{content[field]}</span></div></div>
    </div>}
/*
const Startgroup = (props) => {
        let {content,salirGrupo,global,id}=props
        let [link,setLink] = useState(content.grupoconversacion.joinurl)
        let img = content.grupoconversacion.cursos_grupos_conversaciones_tema.img
        if(img === null || img === ""){
            img=noimg
        }else{
            img=global.domain+img
        }

        const checkLink = () => {
            let params = {
                id:id
            }
            let url=global.domain+"api/v1/checkiLinkGrupo/"+global.apicode+'/'
            fetching(url,params,setLink,null)
        }

        useEffect(()=>{
            const interval=setInterval(()=>{
                checkLink()
            },10000)
            return()=>clearInterval(interval)
        })


        return <div>
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-7">
                            <div className="card card-people-two">
                                <div className="card-header">
                                    <div className="pos-absolute a-0">
                                        <img src={bgimg} alt="" />
                                    </div>
                                    <div className="avatar avatar-md">
                                        <div className="bgimgh rounded-circle"
                                             style={{widht:'84px',height:'84px',backgroundImage:'url('+img+')'}}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="text-muted">Ha comenzado el grupo de conversación:</p>
                                    <h5 className="card-title mb-0">{content.grupoconversacion.nombre}</h5>
                                    <p className="mb-3">{content.grupoconversacion.cursos_curso.nombre}</p>
                                    <p>Para entrar, clica en el siguiente botón. Dale a zoom, permitir.</p>
                                    <a className="btn btn-lg my-3 btn-success"
                                         id="linktojoin"
                                         target="_blank"
                                         rel="noreferrer"
                                         href={link}>Entrar</a>
                                </div>
                                <div className="card-footer text-center bg-light pt-3">
                                    <h5 className="font-weight-light mb-0">Quiere salir del grupo? <button
                                        onClick={()=>salirGrupo()}
                                        className="link link-danger">Salir
                                        del grupo aquí.</button></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }


const Chatstructure = (props) => {
    let {id,userid,content,user,data,salirGrupo,setContent,paramstoload,global}=props
    const charactersavailable=100
    const [variant,setVariant]=useState('success')
    const element=document.getElementById('chat-body-content')
    const [messages,setMessages]=useState([])
    const [lastmsgid,setLastMsgId]=useState(null)
    const [chatVal,setChatVal]=useState("")
    const [countcharactersavailable,setCountcharactersavailable]=useState(charactersavailable)
    const [displayGoBottom,setdisplayGoBottom]=useState(false)

    const submitMsg = () =>{
        setChatVal("")
        setCountcharactersavailable(charactersavailable)
        document.getElementById('inputsubmit').value=""
        let params = {
            id:id,
            userid:userid,
            type:paramstoload.typeaccount,
            msg:chatVal
        }
        let url=global.domain+"api/v1/submitmessagesgruposdeconversacionV3/"+global.apicode+'/'
        fetching(url,params,null,null)
    }

    const goBottom = () =>{
        let heightChat=element.offsetHeight
        let scrollHeight=element.scrollHeight
        let actualheight = scrollHeight-heightChat
        setdisplayGoBottom(false)
        element.scrollTop = actualheight
    }

    const setNewVal = (value) => {
        let leng=value.length
        leng=charactersavailable - leng

        if(leng < (charactersavailable/2)){
            setVariant('warning')
        } else if(leng <= 1){
            setVariant('danger')
        } else if(leng > (charactersavailable/2)){
            setVariant('success')
        }
        setCountcharactersavailable(leng)
    }

    const refreshGrupo = () =>{
        let params = {
            id:id,
            userid:userid,
            type:paramstoload.typeaccount,
            lastmsgid:lastmsgid
        }

        params=btoa(JSON.stringify(params))
        let url=global.domain+"api/v1/getDataGrupoConversacion/"+global.apicode+'/'+params

        fetch(url).then(result=>result.json()).then(json=>{
            setLastMsgId(json.result.mensajes.lastmsgid)
            let arr=messages
            json.result.mensajes.mensajes.map(item=>arr.push(item))
            setMessages(arr)
            if(element !== null){
                let heightChat=element.offsetHeight
                let scrollHeight=element.scrollHeight
                let actualheight = scrollHeight-heightChat
                let scrollTop=element.scrollTop
                if(scrollTop < actualheight && content.mensajes.mensajescount > 0){
                    setdisplayGoBottom(true)
                }
            }
            setContent(json.result)

        })
    }

    useEffect(()=>{
        const interval=setInterval(()=>{
            refreshGrupo()
        },1000)
        return()=>clearInterval(interval)
    })

    return <div>
        {content === null ? <Loader/> :
            <div>
                <Row className={'chat-panel'}>
                    <Col md={3} lg={3}>
                        <div className={'chat-sidebar'}>
                            <div className="chat-sidebar-header">
                                <h6 className="p-2 mb-0">Quienes están en el grupo.</h6>
                                <Badge>BETA</Badge>
                            </div>
                            <div className={'chat-sidebar-body'}>
                                <ul className="chat-list spacechat">
                                    <div className="spaceteachers">
                                        {content.profesoresconectados.map((profesor,index)=><Itemchat key={'itemchat'+index} global={global} type={2} content={profesor.cursos_profesore}/>)}
                                    </div>
                                    <div className="spacestudent">
                                        {content.estudiantesconectados.map((estudiante,index)=><Itemchat key={'itemchatpro'+index} global={global} type={1} content={estudiante.user}/>)}
                                    </div>
                                </ul>
                            </div>
                            <div className={'chat-sidebar-footer'}>
                                <h6 className="chat-loggeduser">{user.user.Nombre} <span>(Yo)</span></h6>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={'chat-body'}>
                            <div className={'chat-body-header'}>
                                <div className="chat-body-header">
                                    <h6 className="font-weight-light mb-0">Sala de espera grupo de conversación <strong>{data.grupo.name}</strong>.</h6>
                                    <div className="chat-body-options">
                                        <Button variant={'light'} onClick={()=>salirGrupo()} className="btn btn-outline-light">Salir</Button>
                                    </div>
                                </div>
                            </div>
                            <div id={'chat-body-content'} className={'chat-body-content ps ps--active-y'}>
                                {messages.length > 0 ? <ul className={'chat-msg-list loadchatspace'}>
                                    {messages.map(item=>{
                                            let name
                                            let img
                                            let classnname=''
                                            let date=''
                                            if(item.type !== 0 && item.user !== null){
                                                name=item.user.Nombre
                                                img=item.user.img
                                                if(paramstoload.typeaccount === 1 && item.type === 1 && item.userid === paramstoload.userid){
                                                    classnname='reverse'
                                                }
                                            }else if(item.type !== 0 && item.cursos_profesore !== null){
                                                name=item.cursos_profesore.nombre
                                                img=item.cursos_profesore.img
                                                if(paramstoload.typeaccount === 2 && item.type === 2 && item.userid === paramstoload.userid){
                                                    classnname='reverse'
                                                }
                                            }
                                            if(img === null){
                                                img = noimg
                                            }else{
                                                img = global.domain+img
                                            }

                                            return <>
                                                {item.type !== 0 ? <li className={'msg-item '+classnname}>
                                                    <div className="avatar avatar-sm"><span
                                                        className="avatar-initial bgimgh rounded-circle"
                                                        style={{backgroundImage:'url('+img+')'}}></span>
                                                    </div>
                                                    <div className="msg-body">
                                                        <h6 className="msg-user">{name}<span>{date}</span></h6>
                                                        <p><span>{item.mensaje}</span></p>
                                                    </div>
                                                </li> : <li className="divider-text">{item.mensaje}</li>}
                                            </>
                                        }
                                    )}
                                </ul>:null}
                            </div>
                            <div className={'position-relative'}>
                                {displayGoBottom ? <Button className={'downbutton'} variant={'danger'} style={{position:'absolute',bottom:'55px', right:'5px'}} onClick={()=>goBottom()}><ChevronDownCircle width={'23px'} shake height={'23px'} color={'#fff'}/></Button> : null}
                                <div className={'chat-body-footer'}>
                                    <div className="chat-body-options">
                                    </div>
                                    <div className="form-group">
                                        <input id="inputsubmit" type="text"
                                               value={chatVal}
                                               onKeyUp={(e)=>setNewVal(e.target.value)}
                                               onChange={(e)=>setChatVal(e.target.value)}
                                               className="form-control inputsubmit"
                                               maxLength={charactersavailable}
                                               placeholder="Escribe algo..." />
                                    </div>
                                    <Badge bg={variant}>{countcharactersavailable}</Badge>
                                    <button className="btn btn-icon sendmessage" onClick={()=>submitMsg()}><SendOutline height="20px" width="20px" /></button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} lg={4}>
                        <Cardgroup global={global} data={data}/>
                    </Col>
                </Row>
            </div>
        }</div>
    }
*/
const Cardgroup = (props) =>{
    let {global,data} = props
    return <div className="card card-hover card-profile-one">
        <div className="row no-gutters">
            <div className="col-md">
                <div className="relative bgimgh"
                     style={{backgroundImage:'url('+global.domain+data.grupo.cursos_grupos_conversaciones_tema.img+')', overflow: 'hidden', height: '100%'}}>
                </div>
            </div>
            <div className="col-md-7">
                <div className="card-body px-0">
                    <div className="media">
                        <div className="">
                            <h5 className="card-title">{data.grupo.nombre} - <small
                                className="text-muted">{data.grupo.cursos_grupos_conversaciones_tema.nombre}</small></h5>
                            {data.grupo.descripciontranslate !== null && data.grupo.descripciontranslate !=="" ? <p className="card-desc" dangerouslySetInnerHTML={{__html:data.grupo.descripciontranslate}}></p> : null }
                            <div className="media-footer">
                                <div style={{marginRight:10}}>
                                    <h6>{data.grupo.countmembersactive}/{data.grupo.limite}</h6>
                                    <label>Unidos</label>
                                </div>
                                <div><h6>{dateformating(data.grupo.date)}</h6>
                                    <label>Hora Programada</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

function Card(props){
    let headertitle=props.header
    let linkname=props.linkname
    return (
        <div className={'card card-hover card-contact-one'}>
            <div className="card-header py-2">{headertitle}</div>
            <div className={'card-body'}>{props.content}</div>
            {linkname !== "" ? <a className="btn btn-secondary mt-3" href={props.link}>{linkname}</a> : null }
        </div>
    );
}

class Info extends Component{
    constructor(props){
        super(props)
        this.state={
            domain:props.domain,
            message:"",
            loading:true,
            loadingd:true,
            loadingdt:true,
            userid:props.userid,
            profesordetail:{},
            resources:{},
            started:0
        }
    }

    loadData(){
        let props=this.props;
        let profesordetail='https://cursos.birmingham-institute.com/api/getprofesorbygroup/'+props.groupid+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy'

        fetch(profesordetail)
            .then(function(response) {
                return response.json();
            })
            .then((myJsond) => {
                this.setState({
                    loading:false,
                    profesordetail: myJsond
                });
            });

        let getgroup='https://cursos.birmingham-institute.com/api/getInfod/CursosGruposConversaciones/'+props.groupid+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy/'+props.userid

        fetch(getgroup)
            .then(function(response) {
                return response.json();
            })
            .then((myJsond) => {
                let temaid=myJsond.temaid
                let resourcesdetail='https://cursos.birmingham-institute.com/api/getRecursos/'+temaid+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy'

                fetch(resourcesdetail)
                    .then(function(response) {
                        return response.json();
                    })
                    .then((myJsondds) => {
                        this.setState({
                            loadingdt:false,
                            resources: myJsondds
                        });
                    });

            });



    }

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
    }


    Profesordetails(){
        let profesor=this.state.profesordetail;

        return (
            <div className={'text-center'}>
                <span className={"rounded-circle d-inline-block bgimgh ml-1 tooltipshow"}
                      style={{width:"100px", height:"100px", backgroundImage:"url("+this.state.domain+"/"+profesor.img+")"}}>
                </span>
                <h6>{profesor.nombre}</h6>
            </div>
        )

    }

    Resources(){
        let resources=this.state.resources;
        let counting=0
        if(this.state.loadingdt == true){
            return <div></div>
        } else{
            return <div>
                {resources.length > 0 ?
                    <div className="card mt-3">
                        <div className="card-header py-2"><p className={'mb-0'}>Listado de materiales</p></div>
                        <ul className="list-group">
                            {
                                resources.map(function(item){
                                    let spanvalue="";
                                    counting++;
                                    if(item.previo == 1){
                                        return(
                                            <li className="list-group-item"><a target={'_blank'} href={item.value}>Recurso {counting}
                                                <span className='badge badge-success float-right'>Para ver antes del club de conversación.</span></a>
                                            </li>
                                        )
                                    }else{
                                        return(
                                            <li className="list-group-item"><a target={'_blank'} href={item.value}>Recurso {counting}</a></li>
                                        )
                                    }

                                })}
                        </ul>
                    </div>
                    : null}
            </div>
        }

    }

    render(){
        if(this.props.type == 1){
            return this.Resources()
        }else if(this.props.type == 2){
            return this.Profesordetails()
        }

    }
}

class Refresh extends Component{
    constructor(props){
        super(props)
        this.state={
            timetojoin:"",
            activemembers:"",
            loading:true,
            loadingd:true,
            started:0
        }
    }

    loadData(){
        let props=this.props;
        let status='https://cursos.birmingham-institute.com/api/timeback/'+props.groupid+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy'
        fetch(status)
            .then(function(response) {
                return response.json();
            })
            .then((myJsond) => {
                this.setState({
                    timetojoin: myJsond,
                    started:1,
                    loadingd:false,
                });
            });

        let countmembers='https://cursos.birmingham-institute.com/api/countmembers/'+props.groupid+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy'
        fetch(countmembers)
            .then(function(response) {
                return response.json();
            })
            .then((myJsond) => {
                this.setState({
                    activemembers: myJsond.members
                });
            });

    }

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.loadData(),
            1500
        );
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    timeToJoin(){
        let message=this.state.timetojoin;
        if(this.state.loadingd == false){
            if(message.timelimit == ''){
                return (
                    <span className={'mb-3'}>
                    <span className="badge-light badge">Comenzará en {message.timeleft}</span>
                </span>
                )
            }else{
                return (
                    <span className={'mb-3'}>
                    <span className="badge-light badge">Únete antes de {message.timelimit}</span>
                </span>
                )
            }
        }else{
            return (
                <div></div>
            )
        }
    }

    countingMembers(){
        let message=this.state;
        let groupdetail=this.props.groupdetail
        return (
            <div>
                <h6>{message.activemembers}/{groupdetail.limite}</h6>
                <label>Unidos</label>
            </div>
        )

    }

    render(){
        if(this.props.type == 1){
            return this.timeToJoin()
        }else if(this.props.type == 2){
            return this.countingMembers()
        } else {
            return this.Profesordetails()
        }

    }
}

class Groupdetail extends Component{

    constructor(props) {
        super(props)
        this.state = {
            domain :'https://cursos.birmingham-institute.com/',
            loading:true,
            loadingd:true,
            group:props.groupid,
            topic:props.topic,
            userid:props.userid,
            topicdetails:{},
            groupdetail:{}
        };
    }

    componentDidMount() {
        let value=this.state
        let coursdetail='https://cursos.birmingham-institute.com/api/getInfod/CursosGruposConversaciones/'+value.group+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy/'+value.userid
        let topicdetail='https://cursos.birmingham-institute.com/api/getInfod/CursosGruposConversacionesTemas/'+value.topic+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy/'+value.userid

        fetch(coursdetail)
            .then(function(response) {
                return response.json();
            })
            .then((myJsond) => {
                this.setState({
                    loading:false,
                    groupdetail: myJsond
                });
            });

        fetch(topicdetail)
            .then(function(response) {
                return response.json();
            })
            .then((myJsond) => {
                this.setState({
                    loadingd:false,
                    topicdetails: myJsond
                });
            });

    }

    render() {
        let info=this.state
        if(info.loading == true || info.loadingd == true){
            return (<div>Loading</div>)
        }
        else{
            if(info.topicdetails.img == null){
                info.topicdetails.img="img/noimagen.png";
            }
            return (
                <div>
                    <div className="card card-hover card-profile-one">
                        <div className="row no-gutters">
                            <div className="col-md-3">
                                <div className="relative border-right"
                                     style={{backgroundSize:"auto 100%", backgroundImage:"url("+this.state.domain+"/"+info.topicdetails.img+")", overflow: "hidden", height: "100%"}}>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="card-body">
                                    <div className="media">
                                        <div className="">
                                            <h5 className="card-title mb-1">
                                                {info.topicdetails.nombre} <small className="text-muted">{info.groupdetail.nombre}</small>
                                            </h5>
                                            <span className={'badge badge-info mr-2'}>{info.groupdetail.edad}</span>
                                            <Refresh key={1} type={1} groupid={info.groupdetail.id} />
                                            <p className="card-desc mt-1">
                                                {info.topicdetails.valordescripcion}
                                            </p>
                                            <div className="media-footer">
                                                <Refresh key={1} type={2} groupdetail={info.groupdetail} groupid={info.groupdetail.id} />
                                                <div>
                                                    <h6>{info.groupdetail.dateformated}</h6>
                                                    <label>Hora Programada</label>
                                                </div>
                                                {info.groupdetail.activo ?
                                                    <div>
                                                        <a href={"/cursos/entrargrupo/"+info.groupdetail.id} className="btn btn-sm btn-primary">Entrar al club</a>
                                                    </div>
                                                    : <div>
                                                        <div className={'alert alert-danger'}>Este club no está activo.</div>
                                                    </div>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

function Resources(props) {
    return(
        <Info key={1} domain={props.domain} type={1} userid={props.userid} groupid={props.groupid}/>
    )
}


export const DetalleClub = (props) => {
        let {id, global, user, setParamstoload, paramstoload,setLoading} = props
        let userid = user.user.id_alumno
        const [data,setData]=useState(null)

        const getData = () => {
            let params = {
                id:id,
                userid:userid
            }
            params=btoa(JSON.stringify(params))
            let url=global.domain+"api/v1/detalleGrupoConversacion/"+global.apicode+'/'+params
            fetching(url,params,setData,null)
        }

        useEffect(()=>getData(),['data'])

        return <div> {data === null ? <Loader/> : <div className={'row justify-content-center'}>
                <div className={'col-12 col-md-10'}>
                    <button className={'text-secondary d-inline-block my-4 link'}
                       onClick={()=>changeView(2,null,setParamstoload,paramstoload,setLoading)}>Volver</button>
                </div>
                <div className={'col-12 col-md-7'}>
                    <Groupdetail key={1} groupid={id} userid={userid} topic={data.grupo.temaid} />
                    <Resources key={1} groupid={id} userid={userid} domain={global.domain}/>
                </div>
                <div className={'col-12 col-md-3'}>
                    <div className={'card'}>
                        <div className={'card-header  py-2'}>Profesor organizador</div>
                        <div className={'card-body'}>
                            <Info key={1} type={2} groupid={id} userid={userid} domain={global.domain}/>
                        </div>
                    </div>
                </div>
            </div> }
        </div>
    }


