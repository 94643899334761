import logo from './logo.png'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { MenuOutline, HomeOutline, ChevronBackOutline, AppsOutline, ChatboxOutline, HelpBuoyOutline, EllipseOutline,AlbumsOutline, CreateOutline, FileTrayFullOutline } from 'react-ionicons'
import {useState, createContext, useContext, useEffect} from 'react'
import {Chatgrupo} from './Views/Chatgrupo'
import {Dashboard} from './Views/Dashboard'
import {ChooseConsulta} from './Views/ChooseConsulta'
import {HistorialConsulta} from './Views/HistorialConsulta'
import {PaquetesConsulta} from './Views/PaquetesConsulta'
import {Instructivos} from './Views/Instructivos'
import {Faqs} from './Views/Faqs'
import {CursosActivos} from './Views/CursosActivos'
import {CursosCompletados} from './Views/CursosCompletados'
import {Notificaciones} from './Views/Notificaciones'
import {ModifyProfile} from './Views/ModifyProfile'
import {General} from './Views/General'
//import {Tracking} from './Views/Tracking'
import {Tracking2} from './Views/Tracking2'
import {Consulta} from './Views/Consulta'
import {DetalleClub} from './Views/DetalleClub'
import {Comprar} from './Views/Comprar'
import {fetching, changeView, Loader} from './Generals'
import {Alert, Modal, Tooltip, OverlayTrigger,Dropdown} from 'react-bootstrap'
import noimg from './no-img.jpg'

const Global = createContext({
    apicode:'pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy',
    domain :'https://cursos.birmingham-institute.com/',
})

let styles = {
    iconsm:{
        size:"15px"
    },
    iconmd:{
        size:"30px"
    },
    iconlg:{
        size:"50px"
    },
}

const toggle = (val) => {
    if(val){
        return false
    }else{
        return true
    }
}

const Layout = (props) => {
    const {main,user,alert,setAlert,setParamstoload,paramstoload,setLoading,global, openModal,modalContent,modalShow,setModalShow} = props
    const [showSidebar,setShowSidebar]=useState(true)
    const [statusd, setStatusd] = useState(null)
    const checkStatus = () =>{
        let paramsd = {
            userid:user.user.id_alumno
        }
        paramsd=btoa(JSON.stringify(paramsd))
        let url=global.domain+"api/v1/checkStatusStudents/"+global.apicode+'/'+paramsd
        fetching(url,paramsd,setStatusd)
    }

    useEffect(()=>{
        const interval=setInterval(()=>{
            checkStatus()
        },3000)
        return()=>clearInterval(interval)
    })

    const renderMenucursos = () => {
        let array=[]
        if(user !== null && user.activeCursos !== undefined){
            user.activeCursos.map((item,index)=>{
                let submenits=[{
                    id:0,
                    name:'Continuar',
                    link:()=>changeView(12,item.cursoid,setParamstoload,paramstoload,setLoading),
                    isLink:false,
                    target:"_self"
                }]
                item.cursos_curso.cursos_recursos.map((itemd,index)=>{
                    let content=itemd.html
                    submenits.push({
                        id:index+1,
                        name:itemd.nombre,
                        link:()=>openModal(itemd.nombre,content),
                        isLink:false,
                        target:"_self"
                    })
                    return null
                })
                array.push({
                    id:index,
                    icon:<EllipseOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                    name:item.cursos_curso.nombre,
                    isLink:false,
                    target:"_self",
                    submenu:submenits,
                    link:""
                })

                return null
            })
        }

        return array
    }

    const menu = [
        {
            'id':1,
            'label':'Acciones',
            'menu':[{
                id:1,
                icon:<ChevronBackOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                name:"Volver v.2.0",
                isLink:true,
                submenu:false,
                link:"https://cursos.birmingham-institute.com/cursos/"
            }]
        },
        {
            'id':2,
            'label':'Generales',
            'menu':[{
                id:1,
                icon:<AppsOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                name:"Escritorio",
                isLink:false,
                target:"_self",
                submenu:false,
                link:()=>changeView(2,null,setParamstoload,paramstoload,setLoading)
            }]
        },
        {
            'id':3,
            'label':'Cursos activos',
            'menu':renderMenucursos()
        },
        {
            'id':4,
            label:'Archivos',
            menu:[
                {
                    id:1,
                    icon:<AlbumsOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                    name:"Cursos Activos",
                    isLink:false,
                    target:"_self",
                    submenu:false,
                    link:()=>changeView(8,null,setParamstoload,paramstoload,setLoading),
                },
                {
                    id:2,
                    icon:<AlbumsOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                    name:"Cursos Completados",
                    isLink:false,
                    target:"_self",
                    submenu:false,
                    link:()=>changeView(9,null,setParamstoload,paramstoload,setLoading),
                },
            ]
        },
        {
            'id':5,
            'label':'Consulta',
            'menu':[{
                id:1,
                icon:<ChatboxOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                name:"Consultas",
                isLink:true,
                submenu:[
                    {
                        id:1,
                        name:'Con el profesor',
                        link:()=>changeView(3,null,setParamstoload,paramstoload,setLoading),
                        isLink:false,
                        target:"_self"
                    },
                    {
                        id:2,
                        name:'Historial de consultas',
                        link:()=>changeView(4,null,setParamstoload,paramstoload,setLoading),
                        isLink:false,
                        target:"_self"
                    },
                    {
                        id:3,
                        name:'Paquetes de consultas',
                        link:()=>changeView(5,null,setParamstoload,paramstoload,setLoading),
                        isLink:false,
                        target:"_self"
                    }
                ],
                target:"_self",
                link:global.domain+"cursos/chooseconsulta"
            }]
        },
        {
            'id':6,
            'label':'Soporte y ayuda',
            'menu':[{
                id:1,
                icon:<HelpBuoyOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                name:"Faqs e instructivos",
                isLink:false,
                target:"_self",
                link:"",
                submenu:[
                    {
                        id:1,
                        name:'Instructivos',
                        link:()=>changeView(6,null,setParamstoload,paramstoload,setLoading),
                        isLink:false,
                        target:"_self"
                    },
                    {
                        id:2,
                        name:'Preguntas Frecuentes',
                        link:()=>changeView(7,null,setParamstoload,paramstoload,setLoading),
                        isLink:false,
                        target:"_self"
                    }
                ]
            },
                {
                    id:2,
                    icon:<ChatboxOutline className={'mr-1'} height={styles.iconsm.size} width={styles.iconsm.size} />,
                    name:"Soporte en línea",
                    isLink:true,
                    target:"_blank",
                    link:"https://api.whatsapp.com/send?phone=52113319309524&text=Solicito%20ayuda"
                }
            ]
        },
        {
            'id':7,
            'label':'Birminham V3.0',
            'menu':[]
        }
    ]

    const toggleSubmenu = (e) => {
        let parent=e.target.parentNode
        if(parent.classList.contains('show')){
            parent.classList.remove('show')
        }else{
            parent.classList.add('show')
        }
    }



    let imgprofile=user.user.img
    if(imgprofile == null){
        imgprofile = noimg
    }else {
        imgprofile=global.domain+imgprofile
    }

    return <div className={showSidebar ? null : "toggle-sidebar"}>
        <div className={'sidebar'}>
            <div className={'sidebar-header'}>
                <img src={logo} alt={main.main.name}/>
            </div>
            <div className={'sidebar-body'}>
                <ul className={'nav nav-sidebar'}>
                    {menu.map(item=><>
                            <li key={'label'+item.id} className={'nav-label'}>
                                <label className={'content-label'}>{item.label}</label>
                            </li>
                        {item.menu.map(menui=><li key={'submenu'+menui.id} className={'nav-item'}>
                                {!menui.submenu ? <>
                                    {menui.isLink ? <a className={'nav-link'} target={menui.target} href={menui.link}>{menui.icon} {menui.name}</a> : <button onClick={menui.link} className={'nav-link'}>{menui.icon} {menui.name}</button>}
                                </> :
                                    <>
                                        <button onClick={(e)=>toggleSubmenu(e)} className={'nav-link'}>{menui.icon} {menui.name}</button>
                                        {menui.submenu !== undefined && menui.submenu.length > 0 ? <nav className={'nav nav-sub'}>
                                            {menui.submenu.map(submenu=> {
                                                return <>{submenu.isLink ? <a className={'nav-sub-link'} target={submenu.target} href={submenu.link}>{submenu.name}</a> : <button onClick={submenu.link} className={'nav-sub-link'}>{submenu.name}</button>}</>
                                            })}
                                        </nav> :null}
                                    </>
                                }

                        </li>)}
                        </>)}
                </ul>
            </div>
        </div>
        <div className={'content content-page'}>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {modalContent.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html : modalContent.content}} ></div>
                </Modal.Body>
            </Modal>

            <div className={'header'}>
                <div className={'header-left'}>
                    <MenuOutline onClick={()=>{
                        let val=toggle(showSidebar)
                        setShowSidebar(val)
                    }} color={'#00000'} height={styles.iconmd.size} width={styles.iconmd.size} />
                </div>
                <div className={'header-right'}>
                    <Dropdown className={'dropdown-loggeduser'}>
                        <Dropdown.Toggle drop={'start'} variant="light" id="dropdown-basic">
                            {user.user.Nombre}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>
                                <div className="media align-items-center d-flex py-3">
                                    <div className="avatar">
                                        <img src={imgprofile} className="rounded-circle" alt="" />
                                    </div>
                                    <div className="media-body" style={{marginLeft:15}}>
                                        <h6 className={'my-0'}>{user.user.Nombre}</h6>
                                    </div>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={()=>changeView(11,null,setParamstoload,paramstoload,setLoading)}><CreateOutline color={'#ccc'} /> Modificar perfil</Dropdown.Item>
                            <Dropdown.Item onClick={()=>changeView(10,null,setParamstoload,paramstoload,setLoading)}><FileTrayFullOutline color={'#ccc'} /> Notificaciones</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <span>

                    </span>
                </div>
            </div>
            <div className={'content-header justify-content-between border-bottom'}>
                <button className={'link'}
                    onClick={()=>changeView(2,null,setParamstoload,paramstoload,setLoading)}>
                    <HomeOutline
                    color={'#00000'}
                    height={styles.iconsm.size}
                    width={styles.iconsm.size}
                />
                </button>

                {statusd!== null ? <>
                    {statusd.asesoria.status === undefined || statusd.asesoria.status === 0 ?
                            <>{statusd.sesiones > 0 ? <Alert className={'my-0 py-1 mr-2'} variant={'info'}>Hay profesores disponibles para consultar en línea. <button
                                onClick={()=>changeView(3,null,setParamstoload,paramstoload,setLoading)}
                                className="link">Solicita asesoría</button></Alert> :
                            <Alert className={'my-0 py-1 mr-2'} variant={'warning'}>
                                No tiene sesiones disponibles para consultas. <a href={global.domain+"cursos/comprar/1"} class="alert-link">Comprar sesiones</a>
                            </Alert>}
                            </>
                        :
                        <Alert variant={statusd.asesoria.status === 1 ? "success" : "danger"} className={'py-1 my-0 mr-2'}>{statusd.asesoria.statusmsg} <button className={'link '}
                                                                                                                                                              onClick={()=>changeView(14,{cursoid:statusd.asesoria.cursoid, itemid:statusd.asesoria.itemid},setParamstoload,paramstoload,setLoading)}>
                            Entrar a la sala de espera.
                        </button>
                        </Alert>
                    }
                            <div className={'d-flex'}>
                            {statusd.profesoresenlinea.map(prof=>{
                                let profesor=prof.cursos_profesore
                                let imgpro=profesor.img
                                if(imgpro == null ){
                                    imgpro=noimg
                                }else{
                                    imgpro=global.domain+imgpro
                                }
                                return <OverlayTrigger
                                    key={'tolltipprofesoresdisponibles'+profesor.id}
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id={'tooltipprofesor'+profesor.id}>{profesor.nombre}</Tooltip>
                                    }>
                                    <div title={profesor.nombre}
                                          className="rounded-circle bgimgh ml-1 tooltipshow"
                                          style={{width:'33px', height:'33px', backgroundImage:'url('+imgpro+')'}}></div>
                                </OverlayTrigger>
                            })}
                            </div>

                    </>
                    : null}


            </div>
            <div className={'content-body'}>
                {alert ? <Alert bg={alert.type}
                                onClose={() => setAlert(false)}
                                dismissible>
                    {alert.alert}</Alert> : null}
                {props.children}
            </div>
        </div>

    </div>
}

const getUserDetails = (global,fn,paramstoload) =>{

    let objecttosend = {userid : paramstoload.userid}
    let url=global.domain+"api/v1/getStudentDetailInfo/"+global.apicode+'/'
    fetching(url,objecttosend,fn,'user')
}

const getMainDetails = (global,fn) =>{
    let url=global.domain+"api/v1/getMainData/"+global.apicode+'/'
    fetching(url,null,fn,'mainData')
}

const App = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const currentdomain=window.location.hostname
    let global = useContext(Global)
    let parameters
    if(currentdomain === 'localhost'){
        parameters= {
            typeaccount:1,
            userid:9181250,
            itemid:1,
            view:13
        }
    }else{
        parameters = JSON.parse(atob(urlParams.get('v3id')))
    }
    const [loading, setLoading] = useState(false)
    const [paramstoload, setParamstoload] = useState(parameters)

    //let storagedUser = JSON.parse(localStorage.getItem('user'))

    const [user, setUser] = useState(null)

    let alertmsg = JSON.parse(localStorage.getItem('alert'))
    const [alert, setAlert] = useState(alertmsg)

    let storagedMain =  JSON.parse(localStorage.getItem('mainData'))
    const [main, setMain] = useState(storagedMain)

    const [modalContent, setModalContent] = useState({title:null,content:null})

    const [modalShow, setModalShow] = useState(false);
    if(user === null){
        getUserDetails(global,setUser,paramstoload)
    }
    if(main === null){
        getMainDetails(global,setMain)
    }

    const openModal = (title,content) => {
        setModalContent({title:title,content:content})
        setModalShow(true)
    }

    const switchingViews = (val,user,global,setAlert) =>{
        switch (val) {
            case 1:
                return <Chatgrupo setLoading={setLoading} main={main} setAlert={setAlert} id={paramstoload.itemid} setParamstoload={setParamstoload} paramstoload={paramstoload} user={user} global={global}/>;
            case 2:
                return <Dashboard setLoading={setLoading} setParamstoload={setParamstoload} main={main} setAlert={setAlert} id={paramstoload.id} paramstoload={paramstoload} user={user} global={global} />;
            case 3:
                return <ChooseConsulta setLoading={setLoading} setParamstoload={setParamstoload} main={main} setAlert={setAlert} id={paramstoload.id} paramstoload={paramstoload} user={user} global={global} />;
            case 4:
                return <HistorialConsulta setLoading={setLoading} setParamstoload={setParamstoload} main={main} setAlert={setAlert} id={paramstoload.id} paramstoload={paramstoload} user={user} global={global} />;
            case 5:
                return <PaquetesConsulta setLoading={setLoading} setParamstoload={setParamstoload} main={main} setAlert={setAlert} id={paramstoload.id} paramstoload={paramstoload} user={user} global={global} />;
            case 6:
                return <Instructivos setLoading={setLoading} setParamstoload={setParamstoload} main={main} setAlert={setAlert} id={paramstoload.id} paramstoload={paramstoload} user={user} global={global} />;
            case 7:
                return <Faqs setLoading={setLoading} setParamstoload={setParamstoload} main={main} setAlert={setAlert} id={paramstoload.id} paramstoload={paramstoload} user={user} global={global} />;
            case 8:
                return <CursosActivos setLoading={setLoading} setParamstoload={setParamstoload} main={main} setAlert={setAlert} id={paramstoload.id} paramstoload={paramstoload} user={user} global={global} />;
            case 9:
                return <CursosCompletados setLoading={setLoading} setParamstoload={setParamstoload} main={main} setAlert={setAlert} id={paramstoload.id} paramstoload={paramstoload} user={user} global={global} />;
            case 10:
                return <Notificaciones setLoading={setLoading} setParamstoload={setParamstoload}
                                       main={main} setAlert={setAlert} id={paramstoload.id} paramstoload={paramstoload} user={user} global={global}
                                       openModal={openModal}
                />
            case 11:
                return <ModifyProfile setLoading={setLoading} setParamstoload={setParamstoload}
                                      main={main} setAlert={setAlert} id={paramstoload.id}
                                      paramstoload={paramstoload} user={user} global={global} setUser={setUser} />
            case 12:
                return <General setLoading={setLoading} main={main} setAlert={setAlert} id={paramstoload.itemid}
                                setParamstoload={setParamstoload} paramstoload={paramstoload} user={user} global={global}/>
            case 13:
                return <Tracking2 setLoading={setLoading} main={main} setAlert={setAlert} id={paramstoload.itemid}
                                setParamstoload={setParamstoload} paramstoload={paramstoload} user={user} global={global}/>
            case 14:
                return <Consulta setLoading={setLoading} main={main} setAlert={setAlert} id={paramstoload.itemid}
                                 setParamstoload={setParamstoload} paramstoload={paramstoload} user={user} global={global}/>
            case 15:
                return <DetalleClub setLoading={setLoading} main={main} setAlert={setAlert} id={paramstoload.itemid}
                                 setParamstoload={setParamstoload} paramstoload={paramstoload} user={user} global={global}/>
            case 16:
                return <Comprar setLoading={setLoading} main={main} setAlert={setAlert} id={paramstoload.itemid}
                                    setParamstoload={setParamstoload} paramstoload={paramstoload} user={user} global={global}/>
            default:
                return null;
        }
    }

    return <div>{loading || user == null ? <Loader/> : <Layout modalShow={modalShow} modalContent={modalContent} openModal={openModal}
                                                               setModalShow={setModalShow}
                                                               global={global} main={main} setLoading={setLoading} user={user} alert={alert} setAlert={setAlert} setParamstoload={setParamstoload} paramstoload={paramstoload}>{switchingViews(paramstoload.view,user,global,setAlert)} </Layout>}</div>
}

export default App
