import {Cardstructure, changeView} from "../Generals"
import {Alert, Col, Row} from "react-bootstrap";

export const CursosActivos = (props) => {
    let {user,global,setParamstoload,paramstoload,setLoading} = props

    return <div className={'text-center py-5'}>
        <h3 className={'mb-4 font-weight-medium'}>Cursos activos</h3>
        {user.activeCursos !== undefined && user.activeCursos.length > 0 ?
            <Row className={'justify-content-center'}>
                {user.activeCursos.map(item=>{
                    let expirationdate = Date.parse(item.expirate_date)
                    let today = Date.parse(new Date())
                    return <Col lg={4} md={3} sm={12} key={'cursoactivo'+item.id}>
                        <Cardstructure width={33} img={global.domain+item.cursos_curso.img} title={item.cursos_curso.nombre}>
                            Grupos asistidos : {item.asistidogruposdeconversaciones}
                            {expirationdate < today && item.completed === 0 ? <a href={global.domain+"/cursos/redirectvencimiento/"+item.cursos_curso.id} class="mt-3 btn btn-outline-warning text-warning btn-block">Ampliar contrato</a>
                                : <button onClick={()=>changeView(12,item.cursos_curso.id,setParamstoload,paramstoload,setLoading)} className="mt-3 w-100 btn btn-block btn-outline-success">Continuar</button> }
                        </Cardstructure>
                    </Col>})
                }
            </Row>
            :
            <Alert variant={'info'}>No tiene cursos activos</Alert>
        }
    </div>

}
