import {useState} from "react"
import {Loader, GlobalIndex} from "../Generals"

export const Instructivos = (props) => {
    let {user,global} = props
    const [instructivos,setInstructivos]=useState(null)
    const [page,setPage]=useState(1)
    const [pages,setPages]=useState(1)
    const [keyword,setKeyword]=useState(null)
    const [disabledval,setDisabledval]=useState(true)
    const [disabledvalnext,setDisabledvalnext]=useState(true)
    const loadInstructivos = () => {
        let params = {
            keyword:keyword,
            page:page
        }
        let url=global.domain+"api/v1/instructivosData/"+global.apicode+'/'
        params=btoa(JSON.stringify(params))
        url=url+'/'+params

        fetch(url).then(result=>result.json()).then(json=>{
            setPages(json.result.pages)
            if(json.result.pages > 1){
                setDisabledvalnext(false)
            }
            setInstructivos(json.result)
            return
        })
    }


    const changePage = (val) => {
        setInstructivos(null)
        setPage(val)
        if(val == 1){
            setDisabledval(true)
        }else{
            setDisabledval(false)
        }
        if(val < pages){
            setDisabledvalnext(false)
        }else{
            setDisabledvalnext(true)
        }
    }

    if(instructivos === null){
        loadInstructivos()
    }

    const content = (item) => {
        console.log(item)
        return <li className="list-group-item">
            <div className="list-body">
                <a className="btn btn-info float-right" target="_blank"
                   href={global.domain+item.document}>Ver</a>
                <h6>{item.titulo}</h6>
                <p>{item.descriptiontranslated}</p>
            </div>
        </li>
    }

    return <>{instructivos== null ? <Loader/> : <GlobalIndex title={'Instructivos'} data={instructivos.instructivos} content={content} changePage={changePage} disabledval={disabledval} disabledvalnext={disabledvalnext} page={page} pages={pages}></GlobalIndex>}</>
}
