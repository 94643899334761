import {useState} from "react"
import {Loader, GlobalIndex} from "../Generals"
import dateformat from 'dateformat'


export const HistorialConsulta = (props) => {
    let {user,global} = props
    const [historial,setHistorial]=useState(null)
    const [page,setPage]=useState(1)
    const [pages,setPages]=useState(1)
    const [disabledval,setDisabledval]=useState(true)
    const [disabledvalnext,setDisabledvalnext]=useState(true)
    const loadHistorial = () => {
        let params = {
            userid:user.user.id_alumno,
            page:page
        }
        let url=global.domain+"api/v1/historialData/"+global.apicode+'/'
        params=btoa(JSON.stringify(params))
        url=url+'/'+params
        fetch(url).then(result=>result.json()).then(json=>{
            setPages(json.result.pages)
            if(json.result.pages > 1){
                setDisabledvalnext(false)
            }
            setHistorial(json.result)
            return
        })
    }


    const changePage = (val) => {
        setHistorial(null)
        setPage(val)
        if(val == 1){
            setDisabledval(true)
        }else{
            setDisabledval(false)
        }
        if(val < pages){
            setDisabledvalnext(false)
        }else{
            setDisabledvalnext(true)
        }
    }

    if(historial === null){
        loadHistorial()
    }

    const content = (item) => {
        let status = "Terminado"
        let statusClass= "badge-success"
        if(item.cancelado === true){
            status="Cancelado"
            statusClass= "badge-danger"
        }

        return <li key={'tistitem'+item.id} className={'list-group-item'}>
            <div className={'avatar'}>
                <img src={global.domain+item.cursos_profesore.img} className="rounded-circle" alt="" />
            </div>
            <div className="list-body">
                <h6>{item.id}. {item.cursos_profesore.nombre}</h6>
                <span className={"badge "+statusClass}>{status}</span>
                <p>Inclusión en la lista: <strong>{dateformat(item.inclusion, 'dd mmm yyyy hh:mm TT')}</strong></p>
            </div>

            <div>
                <nav className="nav">
                    {item.cursos_item !== null && item.cursos_item.titulo !== undefined ? <span className="badge badge-primary mr-2">{item.cursos_item.titulo} - {item.cursos_item.cursos_modulo.titulo}</span> : null }
                    {item.terminado ? <>
                        <span className="badge badge-secondary mr-2">Solicitado el {dateformat(item.inclusion, 'dd mmm yyyy hh:mm TT')}</span>
                        <span className="badge badge-success mr-2">Comezado el {dateformat(item.inicioconsulta, 'dd mmm yyyy hh:mm TT')}</span>
                        <span className="badge badge-info mr-2">Terminado el {dateformat(item.finalconsulta, 'dd mmm yyyy hh:mm TT')}</span>
                    </> : null}
                    {item.cancelado ? <span className="badge badge-warning">Cancelado</span> : null}
                </nav>
            </div>
        </li>
    }

    return <>{historial== null ? <Loader/> :<GlobalIndex title={'Historial de consultas'} data={historial.historial} content={content} changePage={changePage} disabledval={disabledval} disabledvalnext={disabledvalnext} page={page} pages={pages}></GlobalIndex>}</>

}

