import {Cardstructure, fetching, Loader, storeLocal} from "../Generals"
import {Form, Alert, Row, Col} from 'react-bootstrap'
import {useState, useEffect} from "react"
import noimg from './../no-img.jpg'

export const ModifyProfile = (props) => {
    let {user,global,setUser} = props
    const [content,setContent]=useState(false)
    const [objectToSend,setObjectToSend]=useState({})
    const [loading,setLoading]=useState(false)

    const loadData = () => {
        let params = {
            userid:user.user.id_alumno
        }
        let url=global.domain+"api/v1/editProfileData/"+global.apicode+'/'
        fetching(url,params,setContent,null)
    }

    let img= user.user.img
    if(img == null){
        img=noimg
    }else{
        img=global.domain+img
    }

    const setValueToSend = (value,key) =>{
        if(value !== ""){
            let obj=objectToSend
            obj[key]=value
            setObjectToSend(obj)
        }
    }

    const submitValues = () => {
        setLoading(true)
        let params = {
            userid:user.user.id_alumno
        }
        params=btoa(JSON.stringify(params))
        let urld=global.domain+"api/v1/submitEditProfile/"+global.apicode+'/'+params
        var formData = new FormData()
        formData.append('form', JSON.stringify(objectToSend))

        if(objectToSend['img'] !== undefined){
            formData.append('filetosend', objectToSend['img'][0])
        }
        fetch(urld, {
            method: 'POST',
            body: formData
        })
            .then(response => response.text())
            .then(data => {
                data=JSON.parse(data)
                setUser(data.result)
                storeLocal('user',data.result)
                setLoading(false)
            })
            .catch(error => {
                console.error(error)
            })
    }
    useEffect(loadData,['content'])
    return <div>
        {!content ?
            <Loader/>
        :
            <Cardstructure>
                <Row>
                    <Col className={''}>
                        <span className={'d-flex justify-content-center'}>
                            <div className="avatar modifyprofile">
                                <span className="avatar-initial bgimgh rounded-circle" style={{backgroundImage:'url('+img+')'}}></span>
                            </div>
                        </span>
                        <input type="file" className="form-control profilepicinput mt-3 w-100 d-block"
                               onChange={(e)=>{
                                   setValueToSend(e.target.files,"img")}}
                               name="img[]" />

                        <div className="form-group changepassword mt-3">
                            <label className="form-label">Contraseña</label>
                            <input type="password" name="password" onChange={(e)=>setValueToSend(e.target.value,"PASSWORD")}
                                   className="form-control passinput" placeholder="Nueva contraseña" />
                        </div>
                        {!loading ? <button className="btn btn-info mt-3 btn-block w-100" onClick={()=>submitValues()} type="submit">Guardar cambios</button> : <Loader/>}

                    </Col>
                    <Col xs={12} sm={12} md={9}>
                        <h6 className="tx-uppercase tx-semibold tx-color-01 mg-b-0">Datos del perfil</h6>
                        <div className="form-settings">
                            <div className="form-group">
                                <label className="form-label">Nombre Completo</label>
                                <input type="text" className="form-control" name="name"
                                       //onChange={(e)=>setValueToSend(e.target.value,"Nombre")}
                                       placeholder="Escriba su nombre completo" value={user.user.Nombre} readOnly={true}/>
                            </div>

                            <div className="form-group mt-3">
                                <label className="form-label">Nickname</label>
                                <input type="text" className="form-control" name="nickname"
                                       onChange={(e)=>setValueToSend(e.target.value,"nickname")}
                                       placeholder="Escriba su nombre completo" defaultValue={user.user.nickname} />
                                <Alert bg={'warning'} className={'py-1 mt-3'}>Un apodo con el que quieras identificarte.
                                </Alert>
                            </div>
                            <div className="form-group">
                                <label className="form-label">¿Qué quieres aprender? ¿Porqué?</label>
                                <textarea className="form-control" name="porqueaprender" defaultValue={user.user.porqueaprender}
                                       onChange={(e)=>setValueToSend(e.target.value,"porqueaprender")} />
                            </div>

                            <label className="form-label mt-3">Email</label>
                            <input type="text" className="form-control"
                                   readOnly={true}
                                   onBlur={(e)=>setValueToSend(e.target.value,"CORREO_ELECTRONICO")}
                                   placeholder="Correo electrónico"
                                   defaultValue={user.user.CORREO_ELECTRONICO} />
                            <Alert bg={'warning'} className={'py-1 mt-3'}>Sólo es modificable por el administrador.</Alert>

                            <div className="form-group">
                                <label className="form-label">Zona horaria</label>
                                <Form.Select aria-label="Zona horaria" onBlur={(e)=>setValueToSend(e.target.value,"zonahorariaid")}>
                                    {content.zonas.map((i)=><option value={i.id}>{i.nombre}</option>)}
                                </Form.Select>
                                <Alert bg={'warning'} className={'py-1 mt-3'}>Se mostrará el tiempo
                                    equivalente a la zona horaria que escojas.</Alert>
                            </div>
                        </div>
                    </Col>
                </Row>


            </Cardstructure>
        }
    </div>
}
