import {Accordion} from 'react-bootstrap'
import {useState} from "react"
import { Loader, GlobalIndex} from "../Generals"


export const Faqs = (props) => {
    let {user,global} = props
    const [faqs,setFaqs]=useState(null)
    const [page,setPage]=useState(1)
    const [pages,setPages]=useState(1)
    const [keyword,setKeyword]=useState(null)
    const [disabledval,setDisabledval]=useState(true)
    const [disabledvalnext,setDisabledvalnext]=useState(true)
    const loadFaqs = () => {
        let params = {
            keyword:keyword,
            page:page
        }
        let url=global.domain+"api/v1/faqsData/"+global.apicode+'/'
        params=btoa(JSON.stringify(params))

        url=url+'/'+params
        fetch(url).then(result=>result.json()).then(json=>{
            setPages(json.result.pages)
            if(json.result.pages > 1){
                setDisabledvalnext(false)
            }
            setFaqs(json.result)
            return
        })
    }


    const changePage = (val) => {
        setFaqs(null)
        setPage(val)
        if(val == 1){
            setDisabledval(true)
        }else{
            setDisabledval(false)
        }
        if(val < pages){
            setDisabledvalnext(false)
        }else{
            setDisabledvalnext(true)
        }
    }

    if(faqs === null){
        loadFaqs()
    }

    const content = (item,index) => {
        return <li key={'faqs'+item.id} className={'list-group-item m-0 p-0'}>
            <div className="list-bodyss border-bottom w-100 p-4">
                <Accordion defaultActiveKey={0}>
                    <Accordion.Item eventKey={index}>
                        <Accordion.Header className={'bg-light'}>
                            <h5 className={'mb-0'}>{item.titulo}</h5>
                            <p className={'mb-0'}>{item.descriptiontranslated}</p>
                        </Accordion.Header>
                        <Accordion.Body>
                            {item.cursos_faqs.map((itemd,indexd) => {
                                return <div key={'subitem'+indexd+index}>
                                    <h6>{itemd.pregunta}</h6>
                                    <div dangerouslySetInnerHTML={{__html:itemd.respuestatranslated}}></div>
                                </div>
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </li>
    }

    return <>{faqs== null ? <Loader/> : <GlobalIndex title={'Instructivos'} data={faqs.faqs} content={content} changePage={changePage} disabledval={disabledval} disabledvalnext={disabledvalnext} page={page} pages={pages}></GlobalIndex>}</>

}

